import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { CompressorsContainer } from './compressors.container';
import { TabSelectorModule } from '../../components/tab-selector/tab-selector.module';
import { CrossPlotModule } from '../../components/cross-plot/cross-plot.module';
import { PerformanceDriftModule } from '../../components/performance-drift/performance-drift.module';
import { EnergyPerformanceModule } from './components/energy-performance/energy-performance.module';
import { OverviewKpisModule } from '../../components/overview-kpis/overview-kpis.module';
import { OperationalParametersModule } from "../../components/operational-parameters/operational-parameters.module";



const routes: Routes = [
  { path: '', component: CompressorsContainer }
];

@NgModule({
  declarations: [CompressorsContainer],
    imports: [
        CommonModule,
        FormsModule,
        TabSelectorModule,
        CrossPlotModule,
        PerformanceDriftModule,
        EnergyPerformanceModule,
        OverviewKpisModule,
        RouterModule.forChild(routes),
        OperationalParametersModule
    ],
  exports: [CompressorsContainer]
})
export class CompressorsContainerModule { }
